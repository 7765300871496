.matchInfoLink {
    text-decoration: none;
}

.matchInfoLink:hover {
    text-decoration: none;
}

.matchInfo {
    background-color: rgba(0, 0, 0, 0.9);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-decoration: none;
    max-width: 600px;
    width: 90vw;
    margin: 0 auto 20px auto;
    padding-bottom: 1px;
    text-align: center;
}

.matchInfo p {
    padding: 0 5px;
    color: white;
    font-size: 18px;
    margin: 0;
}

.matchInfoHeader {
    background: linear-gradient(
        270deg,
        #000aff -20%,
        #e19494 120%
    );
    color: white;
    height: 50px;
    position: relative;
}

.matchesPage::before {
    content: "";
    position: fixed;
    left: -1%;
    right: -1%;
    top: -1%;
    bottom: -1%;
    z-index: -1;

    display: block;
    background-image: url("../../assets/FencersAngled.jpg");
    background-size: cover;
    background-position: center;
    width: 102%;
    height: 110%;
}

.matchesPage {
    min-height: calc(100vh - 115px);
    background-size: cover;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.matchDeleteButton {
    transition: background-color 0.5s;
    border-radius: 10px;
    width: 25px;
    position: absolute;
    right: 10px;
    top: 25%;
    cursor: pointer;
}

.matchDeleteButton:hover {
    background-color: rgba(255, 255, 255, 0.5);
}
