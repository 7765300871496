.matchParticipantsTable {
    width: 0 !important;
    margin-bottom: 50px !important;
}

.matchParticipantsTable td {
    background-color: white;
    white-space: nowrap;
    line-height: 30px;
    height: 30px;
}
