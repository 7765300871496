.tournamentInfoPage {
    padding-top: 30px;
    text-align: center;
}

.tournamentInfoTable {
    max-width: 600px;
    width: 90vw;
    margin: 15px auto;
}

.boutLog {
    width: 34%;
    height: 244px;
    overflow: auto;
    display: flex;
    flex-direction: column-reverse;
}

.boutEvent {
    font-size: 12px;
    padding: 15px 0;
    position: relative;
}

.boutEvent:first-child {
    margin-top: 15px;
}

.boutEvent:not(:last-child):before {
    content: "";
    position: absolute;
    top: 0;
    left: calc(50% - 25px);
    width: 50px;
    height: 1px;
    border-top: 1px solid white;
}

.boutPlayer {
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    margin: 10px;
    border: 2px solid #666;
    border-radius: 10px;
    padding: 10px;
    overflow: hidden;
}

.boutPlayer h3,
.boutPlayer h5 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.currentBoutScore {
    width: 100%;
    height: 100px;
    font-size: 48px;
    font-weight: bold;
    text-align: center;
    border: 1px solid red;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.liveNowBoutsList {
    display: flex;
    flex-direction: column;
}

.liveNowBout {
    background-color: rgba(8, 8, 8, 0.9);
    display: inline-block;
    margin: auto;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 15px;
    max-width: 90vw;
    cursor: pointer;
}
