@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.backgroundImage::before {
    content: "";
    position: fixed;
    left: -1%;
    right: -1%;
    top: -1%;
    bottom: -1%;
    z-index: -1;

    display: block;
    background-image: url("../../assets/FencersAngled.jpg");
    background-size: cover;
    background-position: center;
    width: 102%;
    height: 110%;
}

.rainbowBoxBG {
    background: linear-gradient(
        270deg,
        #000aff -20%,
        #e19494 120%
    );
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.rainbowBox {
    display: flex;
    justify-content: space-around;
    max-width: 1000px;
    min-height: 56px;
    line-height: 56px;
    font-size: 30px;
    padding: 10px;
    font-weight: bold;
    font-family: "Poppins", sans-serif;
}

.permissionDenied {
    font-family: "Poppins", sans-serif;
    max-width: 800px;
    width: 90%;
    text-align: center;
    padding-top: 100px;
    margin: auto;
}

.rainbowBox p {
    margin: 0;
    color: #EEE;
}

.blackTransparent {
    background-color: rgba(0, 0, 0, 0.9);
    color: #EEE;
    width: 100%;
}
