.matchScoreTable td {
    min-width: 50px;
    height: 50px;
    line-height: 30px;
    white-space: nowrap;
    text-align: center;
    color: black;
}

.matchSummaryTable td {
    height: 50px;
    line-height: 30px;
    white-space: nowrap;
    text-align: center;
    color: black;
}

.matchBlackCell {
    color: white !important;
}

.matchWhiteCell {
    background-color: white !important;
}

.matchInfoPage::before {
    content: "";
    position: fixed;
    left: -1%;
    right: -1%;
    top: -1%;
    bottom: -1%;
    z-index: -1;

    display: block;
    background-image: url("../../assets/FencersAngled.jpg");
    background-size: cover;
    background-position: center;
    width: 102%;
    height: 110%;
}

.matchInfoPage {
    min-height: calc(100vh - 115px);
    background-size: cover;
    padding-top: 30px;
    overflow-x: hidden;
    text-align: center;
}

.matchInfoTable {
    max-width: 90vw;
    width: 600px;
    margin: auto;
}

.rainbowBox {
    display: flex;
    justify-content: space-around;
    max-width: 1000px;
    min-height: 56px;
    line-height: 56px;
    font-size: 30px;
    padding: 10px;
    font-weight: bold;
}

.rainbowBox p {
    margin: 0;
}

.blackTransparent {
    background-color: rgba(0, 0, 0, 0.9);
    color: #EEE;
    width: 100%;
}

.scoreBox {
    width: 50px;
    height: 50px;
    border: 2px solid white;
    border-radius: 10px;
    line-height: 45px;
    font-size: 24px;
    font-weight: 600;
    margin-top: 10px;
}

/* The Modal (background) */

.fencerModalBox {
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100vw;
    /* Full width */
    height: 100vh;
    /* Full height */
    overflow: none;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
    color: #222;
    text-align: left;
}

.fencerModalClose {
    color: #222;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
}
/* Modal Content/Box */

@media only screen and (min-width: 801px) {
    .fencerModalContent {
        background-color: #fefefe;
        margin: 15% auto;
        /* 15% from the top and centered */
        padding: 20px;
        border: 1px solid #888;
        width: 400px;
        border-radius: 10px;
        /* Could be more or less, depending on screen size */
    }
}

@media only screen and (max-width: 500px) {
    .fencerModalContent {
        background-color: #fefefe;
        margin: 25% auto;
        /* 15% from the top and centered */
        padding: 20px;
        border: 1px solid #888;
        width: 90%;
        border-radius: 10px;
        /* Could be more or less, depending on screen size */
    }
}


/* The Close Button */

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.matchInfoBout {
    color: #ddd;
    overflow: hidden;
    transition: all 0.3s ease;
}

.matchInfoBout > p {
    margin: 0;
    cursor: pointer;
    user-select: none;
}

.boutStripData {
    display: flex;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 10px;
    position: relative;
}

.expandButton {
    padding: 5px;
}
