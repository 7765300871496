/* The Modal (background) */

.modalBox {
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100vw;
    /* Full width */
    height: 100vh;
    /* Full height */
    overflow: none;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
    color: #222;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalClose {
    color: #222;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
}
/* Modal Content/Box */

@media only screen and (min-width: 801px) {
    .modalContent {
        background-color: #fefefe;
        margin: 15% auto;
        /* 15% from the top and centered */
        padding: 20px;
        border: 1px solid #888;
        width: 50%;
        border-radius: 10px;
        /* Could be more or less, depending on screen size */
    }
}

@media only screen and (max-width: 800px) {
    .modalContent {
        background-color: #fefefe;
        margin: 25% auto;
        /* 15% from the top and centered */
        padding: 20px;
        border: 1px solid #888;
        width: 90%;
        border-radius: 10px;
        /* Could be more or less, depending on screen size */
    }
}


/* The Close Button */

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}
