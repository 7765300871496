.rosterEditor::before {
    content: "";
    position: fixed;
    left: -1%;
    right: -1%;
    top: -1%;
    bottom: -1%;
    z-index: -1;

    display: block;
    background-image: url("../../assets/FencersAngled.jpg");
    background-size: cover;
    background-position: center;
    width: 102%;
    height: 110%;
}

.rosterEditor {
    min-height: calc(100vh - 115px);
    background-size: cover;
    padding-top: 10px;
}

.form-label {
    margin: 10px 0 0 0 !important;
}

.rosterInput {
    width: 200px;
    height: 40px;
    padding: 5px;
    margin: 10px;
}

.rosterBlockHeader {
    width: 90%;
    max-width: 650px;
    padding: 10px 20px;
    font-size: 24px;
    margin: auto;
    background: linear-gradient(
        270deg,
        #000aff -20%,
        #e19494 120%
    );
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: #EEE;
    text-align: center;
    font-family: "Poppins", sans-serif;
}

.rosterBlock {
    font-family: "Poppins", sans-serif;
    width: 90%;
    max-width: 650px;
    margin: 0 auto 20px auto;
    transition: 0.5s opacity;
    opacity: 1;
    color: #EEE;
    background-color: rgba(0, 0, 0, 0.95);
}

.rosterItem {
    border-top: 1px solid;
    border-width: 1px;
    border-image-slice: 1;
    border-image-source: linear-gradient(
        270deg,
        #000aff -20%,
        #e19494 120%
    );
    padding: 7px 0;
}

.fadingOut {
    opacity: 0;
}

.teamMemberDesc {
    color: #DDD;
}

.deleteIconRoster {
    float: right !important;
    border-radius: 50%;
    transition: 0.2s background-color;
}

.deleteIconRoster:hover {
    background-color: #444;
}

.usersSection {
    margin-bottom: 10px;
}

.disabledButton {
    cursor: not-allowed !important;
    pointer-events: all !important;
    background-color: #999 !important;
    border-color: #999 !important;
}

.requiredStar {
    color: red;
    vertical-align: sub;
    user-select: none;
}
