.createTournamentPage {
    padding-top: 10px;
    text-align: center;
}

.createTournamentBox {
    text-align: initial;
    background-color: white;
    color: #222;
    width: 90%;
    max-width: 1200px;
    margin: auto auto 20px auto;
    border-radius: 10px;
    justify-content: space-between;
}

.createTournamentCol1 {
    width: 100%;
    padding: 20px 20px 0 20px;
}

.createTournamentCol2 {
    width: 100%;
    overflow: auto;
    display: flex;
}

.createTournamentLeftCol {
    border-right: 1px solid black;
    padding: 20px;
    flex-shrink: 0;
    min-width: 50%;
    max-width: 100%;
}

.createTournamentRightCol {
    border-left: 1px solid black;
    padding: 20px;
    flex-shrink: 0;
    min-width: 50%;
    max-width: 100%;
}

@media only screen and (min-width: 801px) {
    .createTournamentBox {
        display: flex;
        height: 574px;
    }
}

@media only screen and (max-width: 800px) {
    .createTournamentCol1 {
        border-bottom: 1px solid grey;
        padding: 20px 20px 0 20px;
    }
}

@keyframes slide-up {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.formQuestionSlim,
.formQuestion {
    display: flex;
    align-items: center;
    animation: slide-up 0.4s ease;
}

.formQuestion > label {
    flex-shrink: 0;
    margin-top: 0.5rem;
    margin-right: 10px;
    width: 150px;
}

.formQuestionSlim > label {
    flex-shrink: 0;
    margin-top: 0.5rem;
    margin-right: 10px;
    width: 75px;
}

.disabledButton {
    cursor: not-allowed !important;
    pointer-events: all !important;
    background-color: #999 !important;
    border-color: #999 !important;
}

.createTournamentTeamsEdit {
    padding: 20px;
}
