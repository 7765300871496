@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.homePage {
    padding-top: 100px;
    filter: initial;
    font-size: 125%;
}

.rainbowBoxBG {
    background: linear-gradient(
        270deg,
        #000aff -20%,
        #e19494 120%
    );
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.rainbowBox {
    display: flex;
    justify-content: space-around;
    max-width: 1000px;
    min-height: 56px;
    line-height: 56px;
    font-size: 30px;
    padding: 10px;
    font-weight: bold;
}

.rainbowBox p {
    margin: 0;
}

.blackTransparent {
    background-color: rgba(0, 0, 0, 0.9);
    color: #EEE;
    width: 100%;
}

.homePageDeleteButtons {
    display: flex;
    justify-content: center;
}

.homePageDeleteButtons > .btn {
    margin: 10px 10px 0 10px;
}
