.teamCreationBox {
    height: calc(100vh - 213px);
    width: 90%;
    margin: auto;
}

.createNewTeamHeader {
    text-align: center;
    color: #fff;
    margin: 20px auto;
    font-size: 3em;
    width: 90%;
}

.formBox {
    min-width: 300px;
    width: 450px;
    padding: 20px;
    border-right: 1px solid black;
    height: 100%;
    flex-shrink: 0.5;
}

.boxData {
    max-width: 1000px;
    margin: auto;
    background-color: #FFF;
    border: 1px solid black;
    border-radius: 5px;
    display: flex;
    height: 390px;
    align-items: center;
}

.boxData > img {
    height: 100%;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    object-fit: cover;
    width: 100%;
}

.form-check {
    margin-bottom: 10px;
}

/* The switch - the box around the slider */

.switch {
    position: relative;
    display: flex;
    align-items: center;
    width: 60px;
    height: 34px;
    width: 100%;
}

/* Hide default HTML checkbox */

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

#bandgteam {
    width: 10px;
    height: 1px;
}

/* The slider */

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    width: 60px;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
}

input:checked+.slider {
    background-color: #2196F3;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.bandgteamText {
    margin-left: 45px;
}

@media only screen and (max-width: 550px) {
    .boxData > img {
        display: none;
    }
    .formBox {
        border-right: initial;
    }
    .createNewTeamHeader {
        font-size: 2.5em;
    }
}

.requiredStar {
    color: red;
    vertical-align: sub;
    user-select: none;
}

.teamCreationPage {
    color: #222;
    text-align: left;
}
