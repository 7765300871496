.Home {
  text-align: center;
}

.Home-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.noscroll {
    position: fixed;
    overflow-y: scroll;
    width: 100%;
}

/* .MuiCard-root {
    background-color: #444 !important;
} */
