.landingPage {
    padding-top: 30px;
}

.landingTable {
    max-width: 90vw;
    width: 700px;
    margin: auto;
}

.landingPageText {
    color: white;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 5px 10px;
    border-radius: 5px;
    margin-bottom: 25px;
    display: inline-block;
    text-align: center;
}

.landingPageNotice {
    max-width: 700px;
    width: 90%;
    border: 3px solid white;
    border-radius: 10px;
    background-color: rgba(186, 124, 166, 0.8);
    margin: 0 auto 20px auto;
    padding: 10px 20px 5px 20px;
}
